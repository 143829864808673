import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Footer } from '../components/footer'
import Layout from '../components/layout'
import img1 from '../components/img/1.jpg'
import img2 from '../components/img/2two.jpg'
import img4 from '../components/img/4.jpg'

import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
  nav {
    background-color: #e1eaf2;
    margin-bottom: 50px;
  }

  footer{
    color: #bacbda;
  }
`

const About = styled.section`
  /* img {
    float: right;
    width: 52%;
  } */
`

class Memory extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <GlobalStyle />
        <div
          style={{
            background: '#e1eaf2',
            borderRadius: 5,
            boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
          }}
        >
          <Helmet title="От памятника к памяти" />
          <About className="wrapper">
            <h2 className="section-headline">
              О проекте: от памятника к памяти
            </h2>
            <div>
              <img src={img1} alt="" />
              <p>
                Памятники заключают в себе наиболее важные сведения о прошлом
                опыте человечества и повсеместно транслируют его в настоящее,
                что способствует не только обогащению человечества историческими
                научными сведениями, но и используется людьми для влияния на
                мировоззрения и коллективную память современников. В Центральной
                части Симферополя на площади 10,26 га расположено Первое
                Симферопольское гражданское кладбище (сейчас известно как Старое
                русское или Старорусское кладбище). Оно существует с 1852 года,
                поэтому на нем похоронены участники и свидетели многих войн –
                Крымской, Первой Мировой, Гражданской и Великой Отечественной.
              </p>
              <p>
                На Старорусском кладбище имеются одиночные и братские
                захоронения около 160 воинов, погибших или умерших от ран в
                1944–1946 годах, а их надгробия по праву можно назвать
                памятниками истории и искусства. На территории кладбища
                расположены 23 военно-мемориальных объекта, внесенных в Единый
                государственный реестр объектов культурного наследия (памятников
                истории и культуры) народов Российской Федерации, а также
                имеются памятники с неопределенным статусом.
              </p>
              <img src={img2} alt="" />
              <p>
                Для сохранения оставшихся уникальных захоронений и инициирована
                реализация проекта по мемориализации кладбища силами историков и
                краеведов города Симферополя. Итогом увековечения памяти
                выдающихся людей, захороненных на Старорусском кладбище в
                Симферополе, станет составление историко-культурного опорного
                плана местности, формирование каталога исторических справок и
                учётных карточек на основе архивных изысканий по памятникам
                Старорусского кладбища, разработка комплексной программы по
                выявлению и сохранению объектов культурного наследия
                Старорусского кладбища, создание интернет-ресурсов о памятниках
                этого места.
              </p>
              <img src={img4} alt="" />
            </div>
          </About>
        </div>
        <Footer text="При финансовой поддержке Фонда президентских грантов, проект № 19-2-012668" />
      </Layout>
    )
  }
}

export default Memory

export const pageQuery = graphql`
  query MemoryQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
